import React, { useState } from "react";
import classes from "./styles.module.css";
import IconButton from "../../../components/IconButton";

import originalIcon from "../../../static/images/icons/original-white.svg";
import magicIcon from "../../../static/images/icons/magic-white.svg";
import grayModeIcon from "../../../static/images/icons/gray-mode-white.svg";
import blackAndWhiteIcon from "../../../static/images/icons/bw-white.svg";
import backIcon from "../../../static/images/icons/backpress.svg";
import doneIcon from "../../../static/images/icons/done.svg";



import { IonLoading } from "@ionic/react";
import { Effects } from "..";
import { Actions, WorkerService } from "../service";

interface EffectProps {
  imageSrc: string;
  onNext: (e: Effects) => void;
  onBack: () => void;
}

const Effect: React.FC<EffectProps> = ({ imageSrc, onNext, onBack }) => {
  const [image, setImage] = useState<string>(imageSrc);
  const [effect, setEffect] = useState<Effects>(Effects.Normal);
  const [loading, setLoading] = useState<boolean>(false);

  const applyEffectOnImage = async (effectName: Effects) => {
    setLoading(true);

    let result;
    if (effectName === Effects.Normal){
      result = imageSrc;
    }

    else {
      result = (await WorkerService.dispatch(Actions.Effect, {
        effect: effectName,
        image: imageSrc,
      })) as any;
    }

    setImage(result);
    setEffect(effectName);
    setLoading(false);
  };

  return (
    <div className={classes.container}>
      <IonLoading isOpen={loading} message={"Please wait..."} />
      <div className={classes.header}>
        This filter will be applied to all pages
      </div>
      <div className={classes.content}>
        <img className={classes.image} src={image} />
      </div>

      <div className={classes.effects_bar}>
        <IconButton
          iconSize="30px"
          iconColor="white"
          labelColor="white"
          icon={originalIcon}
          label="Orginal"
          onClick={() => applyEffectOnImage(Effects.Normal)}
          isSelected={effect === Effects.Normal}
        />
        <IconButton
          iconSize="30px"
          iconColor="white"
          labelColor="white"
          icon={magicIcon}
          label="Magic Color"
          onClick={() => applyEffectOnImage(Effects.Magic)}
          isSelected={effect === Effects.Magic}
        />
        <IconButton
          iconSize="30px"
          iconColor="white"
          labelColor="white"
          icon={grayModeIcon}
          label="Gray Mode"
          onClick={() => applyEffectOnImage(Effects.Gray)}
          isSelected={effect === Effects.Gray}
        />
        <IconButton
          iconSize="30px"
          iconColor="white"
          labelColor="white"
          icon={blackAndWhiteIcon}
          label="B & W"
          onClick={() => applyEffectOnImage(Effects.BW)}
          isSelected={effect === Effects.BW}
        />
      </div>
      <div className={classes.footer}>
        <IconButton
          iconSize="30px"
          iconColor="white"
          labelColor="white"
          icon={backIcon}
          label="Back"
          onClick={onBack}
        />
        <IconButton
          iconSize="30px"
          labelColor="white"
          icon={doneIcon}
          label="Done"
          onClick={() => onNext(effect)}
        />
      </div>
    </div>
  );
};

export default Effect;
