import React, { useState } from "react";
import {
  IonPopover,
  IonIcon,
  IonCard,
  IonCardTitle,
  IonCardSubtitle,
} from "@ionic/react";

import languageIcon from "../../../static/images/icons/languages-card.svg";

import config from "../../../config";
import UpdateLanguageDialog from "../../../components/language";
import classes from "./styles.module.css";

const ChangeLanguage: React.FC = () => {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <>
      <IonCard button onClick={() => setShowPopover(true)}>
        <div className={classes.container}>
          <div className={classes.left}>
            <IonCardTitle>Change language</IonCardTitle>
            <IonCardSubtitle>{config.languages.join(", ")}</IonCardSubtitle>
          </div>
          <IonIcon
            icon={languageIcon}
            className={classes.icon}
          />
        </div>
      </IonCard>
      <IonPopover
        isOpen={showPopover}
        onDidDismiss={(e) => setShowPopover(false)}
      >
        <UpdateLanguageDialog setShowPopover={setShowPopover} />
      </IonPopover>
    </>
  );
};

export default ChangeLanguage;
