import React, { useState } from "react";
import {
  IonCard,
  IonCardSubtitle,
  IonCardContent,
  IonIcon,
  IonPopover,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/react";

import downloadIcon from "../../../static/images/icons/download-pink.svg";
import shareIcon from "../../../static/images/icons/share-pink.svg";
import trashIcon from "../../../static/images/icons/delete-pink.svg";

import classes from "./styles.module.css";
import { downloadImageDoc, downloadPDFDoc } from "../helper";
import { NavLink } from "react-router-dom";

interface Props {
  id: string;
  image: string;
  name: string;
  date: Date;
  share: (input: string) => void;
  delete: (input: string) => void;
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const DocumentItem: React.FC<Props> = (props) => {
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const date = new Date(props.date);
  const dateString = `${date.getDate()} ${
    months[date.getMonth()]
  } ${date.getFullYear()}`;
  return (
    <>
      <IonPopover
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
      >
        <IonList>
          <IonItem
            lines="none"
            onClick={() => {
              downloadPDFDoc(props.id);
              setShowPopover({ showPopover: false, event: undefined });
            }}
          >
            <IonLabel>Download PDF</IonLabel>
          </IonItem>
          <IonItem
            lines="none"
            onClick={() => {
              downloadImageDoc(props.id);
              setShowPopover({ showPopover: false, event: undefined });
            }}
          >
            <IonLabel>Download Images</IonLabel>
          </IonItem>
        </IonList>
      </IonPopover>
      <IonCard>
        <IonCardContent>
          <div className={classes.container}>
            <NavLink to={`/document/${props.id}`}>
              <img className={classes.image} src={props.image} />
            </NavLink>
            <div className={classes.right}>
              <div>
                <div className={classes.title}>{props.name}</div>
                <IonCardSubtitle>{dateString}</IonCardSubtitle>
              </div>
              <div className={classes.buttons}>
                <IonIcon
                  onClick={() => downloadPDFDoc(props.id)}
                  icon={downloadIcon}
                  className={classes.icon}
                />
                
                <IonIcon
                  onClick={() => props.delete(props.id)}
                  icon={trashIcon}
                  className={classes.icon}
                />
              </div>
            </div>
          </div>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default DocumentItem;
