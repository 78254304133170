import React, { useState } from "react";
import { IonButton, IonInput } from "@ionic/react";
import DocumentService from "../../../services/DocumentService";
import classes from "./styles.module.css";

interface Props {
  setShowPopover: (input: boolean) => void;
  setDocumentName: (input: string) => void;
  id: string;
  documentName: string;
}

const RenameDialog: React.FC<Props> = ({
  id,
  documentName,
  setDocumentName,
  setShowPopover,
}) => {
  const [newName, setNewName] = useState<string>(documentName);
  const onSubmit = async () => {
    if (newName.length) {
      await DocumentService.updateDocumentName(id, newName);
      setDocumentName(newName);
    }
    setShowPopover(false);
  };
  return (
    <div className={classes.container}>
      <div>Edit File Name</div>
      <IonInput
        autofocus={true}
        value={newName}
        onIonChange={(e) => setNewName(e.detail.value!)}
      />
      <div className={classes.buttons}>
        <IonButton
          onClick={() => setShowPopover(false)}
          fill="clear"
          size="small"
        >
          CANCEL
        </IonButton>
        <IonButton fill="clear" onClick={onSubmit} size="small">
          SUBMIT
        </IonButton>
      </div>
    </div>
  );
};

export default RenameDialog;
