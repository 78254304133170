import config from '../config';

class IndexedDB {
    constructor() {
        const {database, version} = config.clients.indexedDB;
        let state = 'connecting';
        let db = null;
    
        const openRequest = indexedDB.open(database, version);

        openRequest.onerror = function() {
            state = 'error';
            console.error(`Error: ${openRequest.error}`);
            alert('Trouble connecting to the database, try reconnecting');
        }
        
        openRequest.onsuccess = function() {
            state = 'connected';
            db = openRequest.result;
        }
        
        openRequest.onupgradeneeded = function() {
            state = 'connected';
            db = openRequest.result;

            //Object stores can only be created when db version is upgraded
            //technical limitation, therefore need to create all object stores here

            const objectStores = Object.keys(config.clients.indexedDB.stores).map((key) => {
                return config.clients.indexedDB.stores[key];
            });

            objectStores.map((store) => {
                if (!db.objectStoreNames.contains(store.name)) {
                    db.createObjectStore(store.name, {keyPath: store.primaryKey});
                }
                return true;
            });
        }
  
        this.getDatabase = function() {
            return db;
        }

        this.getState = function() {
            return state;
        }
        
        this.getDatabaseName = function() {
            return database;
        }

        this.getVersion = function() {
            return version;
        }
        
        this.closeDatabse = function() {
            return db.close();
        }

        this.getDatabase = this.getDatabase.bind(this);
        this.getState = this.getState.bind(this);
        this.getDatabaseName = this.getDatabaseName.bind(this);
        this.getVersion = this.getVersion.bind(this);
        this.closeDatabse = this.closeDatabse.bind(this);
    }
}

export default IndexedDB