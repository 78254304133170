import {
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonTitle,
  IonContent,
} from "@ionic/react";
import React, { useState, useEffect } from "react";

import downloadIcon from "../../static/images/icons/download-white.svg";

import {
  pdf,
  Page,
  Text,
  Document,
  PDFDownloadLink,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";

import BackButton from "../../components/DocumentScreen/BackButton";
import ShareButton from "../../components/DocumentScreen/ShareButton";

import DocumentService from "../../services/DocumentService";
import CacheService from "../../services/CacheService";

import classes from "./styles.module.css";
import { useParams } from "react-router";

interface ImageItem {
  id: string;
  image: string;
  dateCreated: Date;
  lastModified: Date;
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    display: "flex",
    backgroundColor: "#fff",
    width: "100%",
  },
  image: {
    margin: "16px",
    objectFit: "contain",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 16,
    textAlign: "right",
    color: "black",
  },
});

const MyDocument = (props: {
  watermark: string | null;
  images: ImageItem[];
}) => (
  <Document>
    {props.images.map((imageData) => {
      return (
        <Page
          key={imageData.id}
          object-fit="fill"
          size="A4"
          style={styles.page}
        >
          <Image src={imageData.image} style={styles.image} />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber }) =>
              `${pageNumber}${" ".repeat(
                75 - pageNumber.toString().length - props.watermark!.length
              )}${props.watermark}`
            }
            fixed
          />
        </Page>
      );
    })}
  </Document>
);

export const PDFBlob = async (documentId: string) => {
  const { images, name } = await DocumentService.getDocument(documentId);
  const watermark = CacheService.getWatermark();
  const myDoc = <MyDocument images={images} watermark={watermark} />;
  return { blob: await pdf(myDoc).toBlob(), name };
};

const PDFPage = (props: {
  pageNumber: number;
  imageData: string;
  watermark: string | null;
}) => (
  <div className={classes.page}>
    <img src={props.imageData} />
    <div className={classes.footer}>
      <div>{props.pageNumber}</div>
      <div>{props.watermark}</div>
    </div>
  </div>
);

const PDFRenderer = (props: {
  watermark: string | null;
  images: ImageItem[];
}) => (
  <div style={{ backgroundColor: "#333333" }}>
    {props.images.map((imageData) => (
      <div key={imageData.id}>
        <PDFPage
          pageNumber={props.images.indexOf(imageData) + 1}
          imageData={imageData.image}
          watermark={props.watermark}
        />
      </div>
    ))}
  </div>
);

const PDF: React.FC = () => {
  const { documentId } = useParams<{ documentId: string }>();
  const [images, setImages] = useState<ImageItem[]>([]);
  const [documentName, setDocumentName] = useState<string>("");
  const watermark = CacheService.getWatermark();

  useEffect(() => {
    (async function () {
      const documentData = await DocumentService.getDocument(documentId);
      if (!documentData) {
        window.location.href = "/404/";
      } else {
        setDocumentName(documentData.name);
        setImages(documentData.images);
      }
    })();
  }, [documentId]);

  const myDoc = <MyDocument images={images} watermark={watermark} />;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons>
            <BackButton location={`/document/${documentId}`} />
            <IonTitle>{documentName}</IonTitle>
            <PDFDownloadLink document={myDoc} fileName={`${documentName}.pdf`}>
              <IonIcon className={classes.downloadIcon} icon={downloadIcon} />
            </PDFDownloadLink>
           
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={classes.IonContentClass}>
        <PDFRenderer images={images} watermark={watermark} />
      </IonContent>
    </IonPage>
  );
};

export default PDF;
