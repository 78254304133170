import { IonTitle } from '@ionic/react';
import React from 'react';
import './DocumentName.css';

interface Props {
    name: string;
    setShowPopover: (input: boolean) => void;
}

const DocumentName: React.FC<Props> = (props) => {
  return (
    <IonTitle slot="start" onClick={() => props.setShowPopover(true)}>
      {props.name}
    </IonTitle>
  );
}
  
export default DocumentName;
  