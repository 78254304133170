import React, { useState } from "react";
import {
  IonItem,
  IonContent,
  IonRadioGroup,
  IonList,
  IonListHeader,
  IonLabel,
  IonRadio,
} from "@ionic/react";
import CacheService from "../../services/CacheService";
import config from "../../config";

interface Props {
  setShowPopover: (input: boolean) => void;
}

const UpdateLanguageDialog: React.FC<Props> = (props) => {
  const language = CacheService.getLangauge();
  const [selected, setSelected] = useState(language);

  return (
    <IonContent>
      <IonList>
        <IonRadioGroup
          value={selected}
          onIonChange={(e) => {
            setSelected(e.detail.value);
            CacheService.changeLanguage(e.detail.value);
            props.setShowPopover(false);
          }}
        >
          <IonListHeader>
            <IonLabel>Choose Language...</IonLabel>
          </IonListHeader>
          {config.languages.map((language) => {
            return (
              <IonItem key={language} lines="none">
                <IonLabel>{language}</IonLabel>
                <IonRadio slot="start" value={language} />
              </IonItem>
            );
          })}
        </IonRadioGroup>
      </IonList>
    </IonContent>
  );
};

export default UpdateLanguageDialog;
