import config from '../config';
import localStorage from '../clients/LocalStorage';

class CacheService {
    static getWatermark() {
        return localStorage.getItem('watermark');
    }

    static setWatermark(watermark) {
        return localStorage.setItem('watermark', watermark);
    }

    static getShutterSound() {
        return localStorage.getItem('shutterSound') === 'true';
    }

    static toggleShutterSound() {
        return localStorage.setItem('shutterSound', !CacheService.getShutterSound());
    }

    static getLangauge() {
        return localStorage.getItem('language');
    }

    static changeLanguage(language) {
        if (!config.languages.includes(language)){
            return new Error('Language out of range');
        }
        return localStorage.setItem('language', language);
    }

    static load() {
        if (this.getWatermark() === null){
            this.setWatermark(config.clients.localStorage.watermark);
        }

        if (this.getShutterSound() === null){
            localStorage.setItem('shutterSound', config.clients.localStorage.shutterSound);
        }

        if (this.getLangauge() === null){
            this.changeLanguage(config.clients.localStorage.language);
        }

        console.log('Cache service loaded');
    }
}

export default CacheService;
