import React, { useState } from "react";
import {
  IonPopover,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
} from "@ionic/react";

import watermarkIcon from "../../../static/images/icons/watermark1-card.svg";

import UpdateWatermarkDialog from "../../../components/watermark";
import classes from "./styles.module.css";

interface Props {
  setShowPopover: (input: boolean) => void;
}

const AddCustomWatermark: React.FC = () => {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <>
      <IonCard button onClick={() => setShowPopover(true)}>
        <IonCardContent className={classes.container}>
          <div className={classes.left}>
            <IonCardTitle>Add Custom Watermark</IonCardTitle>
            <IonCardSubtitle>
              Personalize your scanned documents.
            </IonCardSubtitle>
          </div>
          <IonIcon
            icon={watermarkIcon}
            className={classes.icon}
          />
        </IonCardContent>
      </IonCard>
      <IonPopover
        isOpen={showPopover}
        onDidDismiss={() => setShowPopover(false)}
      >
        <UpdateWatermarkDialog setShowPopover={setShowPopover} />
      </IonPopover>
    </>
  );
};

export default AddCustomWatermark;
