import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  image,
  ellipse,
  checkbox,
  closeCircle
} from "ionicons/icons";

import torchOffIcon from "../../../static/images/icons/torch-off.svg";
import torchOnIcon from "../../../static/images/icons/torch-on.svg";
import cameraIcon from "../../../static/images/icons/camera-click-white.svg";
import filesIcon from "../../../static/images/icons/image.svg";
import proceedIcon from "../../../static/images/icons/green tick rectangle.svg";

import IconButton from "../../../components/IconButton";
import { CameraFooterHeading } from "../../../constants/strings";
import classes from "./styles.module.css";
import { IonIcon, IonLoading } from "@ionic/react";
import { ImageObject } from "../typings";

declare var ImageCapture: any;

interface CameraProps {
  existingImages: ImageObject[];
  onNext: (images: ImageObject[]) => void;
}

const Camera: React.FC<CameraProps> = ({ onNext, existingImages }) => {
  const webcamRef = React.useRef<any>();
  const fileRef = React.useRef<any>();
  const [torch, setTorch] = useState(false);
  const [stream, setStream] = useState<MediaStream>();
  const [images, setImages] = useState<ImageObject[]>(existingImages);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setStream(
          await navigator.mediaDevices.getUserMedia({
            video: {
              // width: { min: 640, ideal: 640 },
              // height: { min: 400, ideal: 400 },
              facingMode: "environment",
            },
          })
        );
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    const webcam = webcamRef.current;
    if (webcam && stream) {
      webcam.srcObject = stream;
      applyTorch(torch);
      webcam.onloadedmetadata = () => webcam.play();
    }
  }, [stream]);

  const applyTorch = (torch: boolean) => {
    stream &&
      stream
        .getVideoTracks()[0]
        // @ts-ignore
        .applyConstraints({ advanced: [{ torch }] });
  };

  useEffect(() => applyTorch(torch), [torch]);

  const capture = async () => {
    if (stream) {
      setLoading(true);
      const imageCapture = new ImageCapture(stream.getVideoTracks()[0]);
      const imageBitmap = await imageCapture.grabFrame();

      const image: any = await new Promise((res) => {
        const canvas = document.createElement('canvas');
          canvas.width = imageBitmap.width;
          canvas.height = imageBitmap.height;
          let ctx = canvas.getContext('bitmaprenderer');
          if(ctx) {
            ctx.transferFromImageBitmap(imageBitmap);
          }
          else {
            canvas.getContext('2d')?.drawImage(imageBitmap,0,0);
          }
          return res(canvas.toDataURL("image/jpeg"))
      });
      setImages([...images, { id: uuidv4(), image }]);
      setLoading(false);
    }
  };

  const addFileToPreview = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString());
      reader.onerror = (error) => reject(error);
    });

  const onFile = async () => {
    const files = fileRef.current.files;
    const filesPromises = [];
    for (let i = 0; i < files.length; i++) {
      filesPromises.push(addFileToPreview(files[i]));
    }
    const result = await Promise.all(filesPromises);
    setImages([...images, ...result.map((image) => ({ id: uuidv4(), image }))]);
  };

  const proceed = () => {
    applyTorch(false);
    onNext(images);
  };

  return (
    <div className={classes.container}>
      <IonLoading isOpen={loading} message={"Please wait..."} />
      <div className={classes.header} onClick={() => setTorch(!torch)}>
        <IonIcon icon={torch ? torchOnIcon : torchOffIcon} style={{fontSize: '30px'}} />
      </div>
      <div className={classes.video_container}>
        <video className={classes.video} ref={webcamRef} />
      </div>
      <div className={classes.footer_container}>
        <div className={classes.horizontal_scroll}>
          <div className={classes.image_list}>
            {images.map((item) => (
              <div key={item.id} className={classes.image_container}>
                <img className={classes.image} src={item.image} />
                <IonIcon
                  icon={closeCircle}
                  size="small"
                  className={classes.close_button}
                  onClick={() =>
                    setImages(images.filter((i) => i.id != item.id))
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.footer_heading}>{CameraFooterHeading}</div>
          <div className={classes.footer_actions}>
            <input
              hidden
              type="file"
              ref={fileRef}
              multiple
              onChange={onFile}
            />
            <IconButton
              icon={filesIcon}
              label="Files"
              labelColor="white"
              iconSize="40px"
              onClick={() => fileRef?.current?.click()}
            />
            <IconButton
              icon={cameraIcon}
              label="Camera"
              labelColor="white"
              iconSize="42px"
              onClick={capture}
            />
            <div className={classes.proceed_button}>
              <IconButton
                icon={proceedIcon}
                label="Proceed"
                labelColor="white"
                iconSize="40px"
                onClick={proceed}
              />
              {images.length > 0 && (
                <div className={classes.proceed_label}>{images.length}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Camera;
