import {
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonPopover,
  IonTitle,
  IonButton,
  IonContent,
  IonIcon,
  IonText,
} from "@ionic/react";
import { add, checkmarkOutline } from "ionicons/icons";

import backButtonIcon from "../../static/images/icons/backpress.svg";

import React, { useState, useEffect } from "react";

import DocumentService from "../../services/DocumentService";
import BackButton from "../../components/DocumentScreen/BackButton";
import DeleteDialog from "./delete";
import RenameDialog from "./rename";
import DocumentName from "../../components/DocumentScreen/DocumentName";
import PDF from "../../components/DocumentScreen/PDF";
import ShareButton from "../../components/DocumentScreen/ShareButton";
import Kebab from "../../components/DocumentScreen/Kebab";
import ImageCard from "./image-card";

import classes from "./styles.module.css";
import SelectedImagesOptionTray from "./options";
import { useHistory, useParams } from "react-router";
import { IDocumentId } from "../typings";
import { NavLink } from "react-router-dom";

interface IImageItem {
  id: string;
  dateCreated: Date;
  lastModified: Date;
  image: string;
}

const Document: React.FC = () => {
  const { documentId } = useParams<IDocumentId>();
  const [images, setImages] = useState<IImageItem[]>([]);
  const [documentName, setDocumentName] = useState<string>("");
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [isSelectionModeOn, setIsSelectionModeOn] = useState<boolean>(false);
  const [showRenameDilaog, setShowRenameDilaog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const history = useHistory();

  const loadedImages = async () => {
    try {
      const documentData = await DocumentService.getDocument(documentId);
      if (!documentData) {
        history.push("/404/");
      } else {
        setDocumentName(documentData.name);
        setImages(documentData.images);
      }
    } catch (e) {
      history.push("/404/");
    }
  };
  useEffect(() => {
    loadedImages();
  }, []);

  const onClickSelectAll = () => {
    if (selectedImages.length < images.length) {
      let imagesSet = new Set<string>();
      images.forEach((imageData) => {
        imagesSet.add(imageData.id);
      });

      let newSelectedImages = Array.from(imagesSet);
      setSelectedImages(newSelectedImages);
    } else {
      setSelectedImages([]);
    }
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar color="primary">
            {isSelectionModeOn ? (
              <IonButtons>
                <IonButton
                  slot="start"
                  onClick={() => setIsSelectionModeOn(false)}
                >
                  <IonIcon icon={backButtonIcon} />
                </IonButton>
                <IonTitle slot="start">{`${selectedImages.length} Selected`}</IonTitle>
                <IonText onClick={onClickSelectAll} className="ion-padding-end">
                  {selectedImages.length < images.length
                    ? "Select All"
                    : "Deselect All"}
                </IonText>
              </IonButtons>
            ) : (
              <IonButtons>
                <BackButton location={"/home"} />
                <DocumentName
                  setShowPopover={setShowRenameDilaog}
                  name={documentName}
                />
                {documentId && <PDF id={documentId} />}
                
                <Kebab
                  setShowRenamePopover={setShowRenameDilaog}
                  setShowDeletePopover={setShowDeleteDialog}
                  setShowSelectedFiles={setIsSelectionModeOn}
                />
              </IonButtons>
            )}
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <div className={classes.container}>
            <div className={classes.image_list_container}>
              <div className={classes.image_list}>
                {images.map((imageData, index) => (
                  <div key={imageData.id}>
                    {documentId && (
                      <ImageCard
                        id={imageData.id}
                        index={index}
                        documentId={documentId}
                        isSelectionModeOn={isSelectionModeOn}
                        selectedImages={selectedImages}
                        setSelectedImages={setSelectedImages}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
            {isSelectionModeOn && documentId && (
              <SelectedImagesOptionTray
                setIsSelectionModeOn={setIsSelectionModeOn}
                selectedImages={selectedImages}
                id={documentId}
                onDelete={() => {
                  loadedImages();
                }}
              />
            )}
          </div>
        </IonContent>

        {!isSelectionModeOn && (
          <div className={classes.fab}>
            <NavLink to={`/scan/${documentId}`}>
              <div className={classes.left}>
                <IonIcon size="large" icon={add} color="light" />
              </div>
            </NavLink>
            <NavLink to="/home">
              <div className={classes.right}>
                <IonIcon size="large" icon={checkmarkOutline} color="light" />
              </div>
            </NavLink>
          </div>
        )}
      </IonPage>
      {documentId && (
        <>
          <IonPopover
            isOpen={showRenameDilaog}
            onDidDismiss={() => setShowRenameDilaog(false)}
          >
            <RenameDialog
              setShowPopover={setShowRenameDilaog}
              setDocumentName={setDocumentName}
              id={documentId}
              documentName={documentName}
            />
          </IonPopover>

          <IonPopover
            isOpen={showDeleteDialog}
            onDidDismiss={() => setShowDeleteDialog(false)}
          >
            <DeleteDialog
              onCancel={() => setShowDeleteDialog(false)}
              onSubmit={async () => {
                await DocumentService.deleteDocument(documentId);
                setShowDeleteDialog(false);
                history.push("/home");
              }}
            />
          </IonPopover>
        </>
      )}
    </>
  );
};

export default Document;
