import { v4 as uuidv4 } from "uuid";

import { documentStore } from "../clients/ObjectStore";
import ImageService from "./ImageService";

class DocumentService {
  static async addDocument(images) {
    try {
      const imageIds = await Promise.all(
        images.map((image) => {
          return ImageService.addImage(image);
        })
      );

      const documentObj = {
        id: uuidv4(),
        // id: '1',
        dateCreated: Date.now(),
        lastModified: Date.now(),
        images: imageIds,
        name: "",
      };

      documentObj.name = "Doc_" + documentObj.dateCreated.toString();

      await documentStore.addItem(documentObj);
      return Promise.resolve(documentObj.id);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async updateDocumentName(documentId, name) {
    try {
      const documentObj = await documentStore.getItem(documentId);

      if (!documentObj) {
        return Promise.reject("Document does not exist!");
      }

      documentObj.name = name;
      return documentStore.updateItem(documentObj);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async addDocumentImage(documentId, images) {
    try {
      const documentObj = await documentStore.getItem(documentId);
      if (!documentObj) {
        return Promise.reject("Document does not exist!");
      }
      const imageIds = await Promise.all(
        images.map((image) => ImageService.addImage(image))
      );

      documentObj.images.push(...imageIds);
      return documentStore.updateItem(documentObj);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async deleteDocumentImage(documentId, imageId) {
    try {
      const documentObj = await documentStore.getItem(documentId);

      if (!documentObj) {
        return Promise.reject("Document does not exist!");
      }

      if (!documentObj.images.includes(imageId)) {
        return Promise.reject("Document does not contain the image!");
      }

      documentObj.images = documentObj.images.filter(
        (imageIdFromDoc) => imageIdFromDoc !== imageId
      );

      const parallelTasks = [ImageService.deleteImage(imageId)];
      if (documentObj.images.length === 0) {
        parallelTasks.push(documentStore.deleteItem(documentObj.id));
      } else {
        parallelTasks.push(documentStore.updateItem(documentObj));
      }

      return Promise.all(parallelTasks);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async reorderDocumentImages(documentId, imageIds) {
    try {
      const documentObj = await documentStore.getItem(documentId);

      if (!documentObj) {
        return Promise.reject("Document does not exist!");
      }

      for (let i = 0; i < imageIds.length; i++) {
        if (!documentObj.images.includes(imageIds[i])) {
          return Promise.reject("Document contains different images!");
        }
      }

      documentObj.images = imageIds;
      return documentStore.updateItem(documentObj);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async deleteDocument(documentId) {
    try {
      const documentObj = await documentStore.getItem(documentId);

      if (!documentObj) {
        return Promise.resolve("Deleted successfully");
      }

      await Promise.all(
        documentObj.images.map((imageId) => {
          return ImageService.deleteImage(imageId);
        })
      );

      return documentStore.deleteItem(documentId);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getDocument(documentId) {
    try {
      const documentObj = await documentStore.getItem(documentId);
      if (!documentObj) {
        return Promise.resolve(documentObj);
      }

      const images = await Promise.all(
        documentObj.images.map((imageId) => ImageService.getImage(imageId))
      );

      documentObj.images = images.filter((item) => !!item);
      return documentObj;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static async getAllDocuments() {
    try {
      const documentList = await documentStore.getAllItems();

      if (!documentList) return Promise.resolve([]);

      return Promise.all(
        documentList.map(async (item) => {
          let image = await ImageService.getImage(item.images[0]);
          image = image.image;
          return {
            ...item,
            image,
            date: item.dateCreated,
          };
        })
      );
    } catch (err) {
      return Promise.reject(err);
    }
  }

  static load() {
    //Necessary to run this as export does not run the file by it self
    //object store file gets imported so code is initialized
    //state of db changes to connected
    return new Promise((resolve, reject) => {
      let intervalID = setInterval(() => {
        if (documentStore.getState() === "connected") {
          console.log("Document service loaded");
          clearInterval(intervalID);
          return resolve(true);
        }
        if (documentStore.getState() === "error") {
          console.log("Document service error");
          clearInterval(intervalID);
          return reject("error occured when loading document service");
        }
      }, 50);
    });
  }

  static clear() {
    return documentStore.clearAllItems();
  }
}

export default DocumentService;
