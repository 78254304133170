const getImageElem = (src: string): Promise<HTMLImageElement> =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.src = src;
  });

const getImageData = async (image: any) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return;
  const img = await getImageElem(image);
  canvas.width = img.naturalWidth;
  canvas.height = img.naturalHeight;
  return new Promise((resolve) => {
    setTimeout(() => {
      ctx.drawImage(img, 0, 0);
      resolve({
        image: ctx.getImageData(0, 0, img.naturalWidth, img.naturalHeight),
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    }, 500);
  });
};

export { getImageElem, getImageData };
