import React, { useState, useEffect } from "react";
import { IonCheckbox } from "@ionic/react";
import classes from "./styles.module.css";

import ImageService from "../../../services/ImageService";
import { useHistory } from "react-router";

interface Props {
  id: string;
  index: number;
  documentId: string;
  isSelectionModeOn: boolean;
  selectedImages: string[];
  setSelectedImages: (input: string[]) => void;
}

const ImageCard: React.FC<Props> = ({
  id,
  index,
  documentId,
  isSelectionModeOn,
  selectedImages,
  setSelectedImages,
}) => {
  const [checked, setChecked] = useState(selectedImages.includes(id));
  const [imageData, setImageData] = useState("");
  const history = useHistory();

  useEffect(() => {
    (async function () {
      const image = await ImageService.getImage(id);
      setImageData(image.image);
    })();
  }, [id]);

  useEffect(() => {
    setChecked(selectedImages.includes(id));
  }, [selectedImages]);

  const onChecked = (checked: boolean) => {
    setChecked(checked);
    const imagesSet = new Set<string>();
    selectedImages.forEach((image) => imagesSet.add(image));
    if (checked) imagesSet.add(id);
    else imagesSet.delete(id);
    setSelectedImages(Array.from(imagesSet));
  };

  return (
    <div
      className={classes.container}
      onClick={() => {
        if (isSelectionModeOn) onChecked(!checked);
        else history.push(`/single/${documentId}/${id}`);
      }}
    >
      {isSelectionModeOn && (
        <IonCheckbox className={classes.checkbox} checked={checked} />
      )}
      <img src={imageData} />
      <div className={classes.index}>{index + 1}</div>
    </div>
  );
};

export default ImageCard;
