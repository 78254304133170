import { Point } from "../typings";

const radius = 20;

const sq = (a: number) => a * a;

const drawCircle = (ctx: CanvasRenderingContext2D, { x, y }: Point) => {
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, Math.PI * 2, true);
  ctx.fillStyle = '#A8B6D188';
  ctx.fill();
  ctx.lineWidth = 2;
  ctx.strokeStyle = '#22AAFF';
  ctx.stroke();
  ctx.closePath();
};

const drawLine = (ctx: CanvasRenderingContext2D, a: Point, b: Point) => {
  ctx.beginPath();
  ctx.moveTo(a.x, a.y);
  ctx.lineTo(b.x, b.y);
  ctx.stroke();
  ctx.closePath();
};

const midPoint = (a: Point, b: Point): Point => ({
  x: (a.x + b.x) / 2,
  y: (a.y + b.y) / 2,
});

const withInRadius = (a: Point, b: Point) =>
  sq(a.x - b.x) + sq(a.y - b.y) <= sq(radius);

const slope = (a: Point, b: Point) => sq((a.y - b.y) / (a.x - b.x)) >= 1;

const add = (a: Point, b: Point): Point => ({ x: a.x + b.x, y: a.y + b.y });

export { drawCircle, drawLine, midPoint, withInRadius, slope, add };
