import {
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonFab,
  IonFabButton,
  IonIcon,
  IonContent,
} from "@ionic/react";
import axios from "axios";
import { add } from "ionicons/icons";
import React, { useState, useEffect } from "react";
import SearchBar from "../../components/HomeScreen/SearchBar";
import Kebab from "../../components/HomeScreen/Kebab";
import AddCustomWatermark from "./watermark";
import ChangeLanguage from "./language";

import DocumentService from "../../services/DocumentService";
import DocumentItem from "./item";
import { NavLink } from "react-router-dom";

export interface IDocumentItem {
  id: string;
  image: string;
  name: string;
  date: Date;
}

function sort(type: string | null, documentList: IDocumentItem[]) {
  if (!type) {
    return documentList;
  }

  if (type === "name") {
    return documentList.sort((a: IDocumentItem, b: IDocumentItem) => {
      return ("" + a.name).localeCompare(b.name);
    });
  } else {
    return documentList.sort((a: IDocumentItem, b: IDocumentItem) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
  }
}

function filter(filterText: string, documentList: IDocumentItem[]) {
  if (filterText === "") {
    return documentList;
  }

  filterText = filterText.toLowerCase();
  return documentList.filter((documentItem: IDocumentItem) => {
    let { name, date } = documentItem;
    name = name.toLowerCase();
    let dateString = date.toString().toLowerCase();

    if (name.includes(filterText)) {
      return true;
    }

    if (dateString.includes(filterText)) {
      return true;
    }

    return false;
  });
}

async function shareDocument(id: string) {
  console.log("Share called for id " + id);
  //@ts-ignore
  if (!navigator.share) return;
  const documentData = await DocumentService.getDocument(id);

  const files = documentData.images.map((imageData: any) => {
    const arr = imageData.image.split(",");
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File(
      [u8arr],
      `${documentData.name}_${documentData.images.indexOf(imageData) + 1}.jpeg`,
      {
        type: "image/jpeg",
      }
    );
  });

  //@ts-ignore
  if (navigator.canShare && navigator.canShare({ files })) {
    try {
      const options = {
        files,
        title: "Kaagaz Scanner",
        text: "Be AatmaNirbhar. Use Kaagaz",
      };
      //@ts-ignore
      await navigator.share(options);
      console.log("Share was successful.");
    } catch (error) {
      console.log("Sharing failed", error);
    }
  } else {
    console.log(`Your system doesn't support sharing files.`);
  }
}

function deleteDocument(id: string) {
  (async function () {
    await DocumentService.deleteDocument(id);
    window.location.href = "/home";
  })();
}

const Home: React.FC = () => {
  const [sortBy, setSortBy] = useState<null | string>(null);
  const [searchText, setSearchText] = useState<string>("");

  const [documentList, setDocumentList] = useState<IDocumentItem[]>([]);

  useEffect(() => {
    (async () => {
      setDocumentList(await DocumentService.getAllDocuments());
    })();
  }, []);

  const sortedDocumentList = sort(sortBy, documentList);
  const filteredDocumentList = filter(searchText, sortedDocumentList);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <SearchBar searchText={searchText} setSearchText={setSearchText} />
          <Kebab sortBy={setSortBy} />
        </IonToolbar>
      </IonHeader>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <NavLink to="/scan">
          <IonFabButton color="success">
            <IonIcon icon={add} />
          </IonFabButton>
        </NavLink>
      </IonFab>
      <IonContent className="ion-padding">
        {filteredDocumentList.map((documentItem) => (
          <DocumentItem
            key={documentItem.id}
            id={documentItem.id}
            image={documentItem.image}
            name={documentItem.name}
            date={documentItem.date}
            share={shareDocument}
            delete={deleteDocument}
          />
        ))}
        <AddCustomWatermark />
        <ChangeLanguage />
      </IonContent>
    </IonPage>
  );
};

export default Home;
