import DocumentService from "./services/DocumentService";
import CacheService from "./services/CacheService";

import React, { useEffect, useState } from "react";
import { Redirect, Route, HashRouter, NavLink } from "react-router-dom";
import {
  IonApp,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonRouterOutlet,
  IonIcon,
  IonPopover,
  setupConfig,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/home";
import Settings from "./pages/settings";
import Recognitions from "./pages/recognitions";
import FAQ from "./pages/faq";
import Privacy from "./pages/privacy";
import Document from "./pages/document";
import PDF from "./pages/pdf";
import _404 from "./pages/404";
import Testing from "./pages/Testing";

import NotHappyWithUsDialog from "./components/not-happy";
import RateUsDialog from "./components/rate-us";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import settingsIcon from "./static/images/icons/settings.svg";
import shareIcon from "./static/images/icons/share-grey.svg";
import notHappyIcon from "./static/images/icons/not-happy.svg";
import ratingIcon from "./static/images/icons/rate-grey.svg";
import recognitionsIcon from "./static/images/icons/award.svg";
import faqIcon from "./static/images/icons/faqs-grey.svg";
import privacyIcon from "./static/images/icons/privacy.svg";

/* Theme variables */
import "./theme/variables.css";
import Scan from "./pages/scan";
import Single from "./pages/single";
import { WorkerService } from "./pages/scan/service";

setupConfig({ mode: "md" });

const App: React.FC = () => {
  const [showNotHappyPopover, setShowNotHappyPopover] = useState(false);
  const [showRateUsPopover, setShowRateUsPopover] = useState(false);
  const [servicesLoaded, setServicesLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      await DocumentService.load();
      await WorkerService.load();
      setServicesLoaded(true);
    })();
    CacheService.load();
    return () => {
      WorkerService.unload();
    };
  }, []);

  if (!servicesLoaded) return null;

  return (
    <IonApp>
      <HashRouter>
        <IonMenu side="start" contentId="main-content" swipeGesture={false}>
          <IonContent>
            <IonList>
              <IonMenuToggle>
                <NavLink to="/settings">
                  <IonItem detail={false} lines="full">
                    <IonIcon
                      icon={settingsIcon}
                      style={{ color: "#333333" }}
                      slot="start"
                    />
                    <div>Settings</div>
                  </IonItem>
                </NavLink>
              </IonMenuToggle>
              <IonMenuToggle>
                <IonItem
                  detail={false}
                  lines="none"
                  onClick={async () => {
                    if (
                      //@ts-ignore
                      navigator.canShare &&
                      //@ts-ignore
                      navigator.canShare({ text: "" })
                    ) {
                      try {
                        //@ts-ignore
                        await navigator.share({
                          title: "Kaagaz Scanner",
                          text:
                            "Download Kaagaz Scanner App today to scan your documents and share PDF/image.\n\nhttps://kaagaz.app/download/11\n\nIndia's very own scanner app - Kaagaz Scanner",
                        });
                        console.log("Share was successful.");
                      } catch (error) {
                        console.log("Sharing failed", error);
                      }
                    } else {
                      console.log(`Your system doesn't support sharing files.`);
                    }
                  }}
                >
                  <IonIcon
                    icon={shareIcon}
                    style={{ color: "#333333" }}
                    slot="start"
                  />
                  <div>Share App</div>
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle>
                <IonItem
                  button
                  onClick={() => setShowNotHappyPopover(true)}
                  detail={false}
                  lines="none"
                >
                  <IonIcon
                    icon={notHappyIcon}
                    style={{ color: "#333333" }}
                    slot="start"
                  />
                  <div>Not Happy With Us?</div>
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle>
                <IonItem
                  detail={false}
                  lines="none"
                  button
                  onClick={() => setShowRateUsPopover(true)}
                >
                  <IonIcon
                    icon={ratingIcon}
                    style={{ color: "#333333" }}
                    slot="start"
                  />
                  <div>Rate Us</div>
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle>
                <NavLink to="/recognitions">
                  <IonItem detail={false} lines="full">
                    <IonIcon
                      icon={recognitionsIcon}
                      style={{ color: "#333333" }}
                      slot="start"
                    />
                    <div>Our Recognitions</div>
                  </IonItem>
                </NavLink>
              </IonMenuToggle>
              <IonMenuToggle>
                <NavLink to="/faq">
                  <IonItem detail={false} lines="none">
                    <IonIcon
                      icon={faqIcon}
                      style={{ color: "#333333" }}
                      slot="start"
                    />
                    <div>FAQ</div>
                  </IonItem>
                </NavLink>
              </IonMenuToggle>
              <IonMenuToggle>
                <NavLink to="/privacy">
                  <IonItem detail={false} lines="none">
                    <IonIcon
                      icon={privacyIcon}
                      style={{ color: "#333333" }}
                      slot="start"
                    />
                    <div>Privacy</div>
                  </IonItem>
                </NavLink>
              </IonMenuToggle>
            </IonList>
          </IonContent>
        </IonMenu>
        <IonPopover
          isOpen={showNotHappyPopover}
          cssClass="my-custom-class"
          onDidDismiss={() => setShowNotHappyPopover(false)}
        >
          <NotHappyWithUsDialog setShowPopover={setShowNotHappyPopover} />
        </IonPopover>
        <IonPopover
          isOpen={showRateUsPopover}
          cssClass="my-custom-class"
          onDidDismiss={() => setShowRateUsPopover(false)}
        >
          <RateUsDialog setShowPopover={setShowRateUsPopover} />
        </IonPopover>
        <IonPage id="main-content">
          <Route path="/home" component={Home} exact={true} />
          <Route
            path="/single/:documentId/:imageId"
            component={Single}
            exact={true}
          />
          <Route path="/settings" component={Settings} exact={true} />
          <Route path="/recognitions" component={Recognitions} exact={true} />
          <Route path="/faq" component={FAQ} exact={true} />
          <Route path="/privacy" component={Privacy} exact={true} />
          <Route
            path="/document/:documentId"
            component={Document}
            exact={true}
          />
          <Route path="/pdf/:documentId" component={PDF} exact={true} />
          <Route path="/404" component={_404} exact={true} />
          <Route path="/scan/:documentId" component={Scan} exact={true} />
          <Route path="/scan" component={Scan} exact={true} />
          <Route exact path="/" render={() => <Redirect to="/home" />} />
        </IonPage>
      </HashRouter>
    </IonApp>
  );
};

export default App;
