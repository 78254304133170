import React, { useEffect, useRef, useState } from "react";
import { IonIcon, IonInput } from "@ionic/react";
import { close, searchOutline } from "ionicons/icons";

interface Props {
  searchText: string;
  setSearchText: (input: string) => void;
}

const SearchBar: React.FC<Props> = ({ searchText, setSearchText }) => {
  const [showSearch, setShowSearch] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) (inputRef.current as any).setFocus();
  });
  return (
    <div
      style={{
        fontSize: "1.2rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginLeft: "8px",
        marginRight: "8px",
      }}
    >
      {showSearch ? (
        <>
          <IonInput
            ref={inputRef}
            color="light"
            value={searchText}
            placeholder="Filter Documents"
            onIonChange={(e) => setSearchText(e.detail.value!)}
          />
          <IonIcon
            icon={close}
            onClick={() => {
              if (searchText) {
                setSearchText("");
                return;
              }
              setShowSearch(false);
            }}
          />
        </>
      ) : (
        <>
          <div>Kagaaz</div>
          <IonIcon icon={searchOutline} onClick={() => setShowSearch(true)} />
        </>
      )}
    </div>
  );
};

export default SearchBar;
