import React, { useState } from "react";
import {
  IonButton,
  IonTitle,
  IonIcon,
  IonInput,
  IonTextarea,
  IonRouterLink,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import config from "../../config";
import classes from "./styles.module.css";

interface Props {
  setShowPopover: (input: boolean) => void;
}

const NotHappyWithUsDialog: React.FC<Props> = ({ setShowPopover }) => {
  const [issue, setIssue] = useState("");
  const [email, setEmail] = useState("");

  return (
    <div className={classes.container}>
      <IonIcon
        className={classes.close}
        onClick={() => setShowPopover(false)}
        icon={closeOutline}
      />
      <div className={classes.title}>Not Happy With Us?</div>
      <div className={classes.textarea_container}>
        <textarea
          className={classes.textarea}
          value={issue}
          placeholder="Please tell us your issues"
          onChange={(e) => setIssue(e.target.value!)}
        />
      </div>
      <input
        className={classes.input}
        value={email}
        placeholder="Please provide your email id"
        onChange={(e) => setEmail(e.target.value!)}
      />
      <IonButton
        color="primary"
        onClick={() => setShowPopover(false)}
        size="small"
      >
        Submit
      </IonButton>
      <IonRouterLink href={`mailto:${config.email}`} className={classes.link}>
        Or email us at {config.email}
      </IonRouterLink>
    </div>
  );
};

export default NotHappyWithUsDialog;
