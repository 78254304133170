import {
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
} from "@ionic/react";
import React, { useState } from "react";
import classes from "./styles.module.css";

const FAQ: React.FC = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>FAQ</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={classes.body}>
        <div className={classes.header}>Frequently Asked Questions</div>
        <div className={classes.question} onClick={() => setToggle(!toggle)}>
          Q: Where are all the scanned documents saved on my phone?
        </div>
        {toggle && (
          <div className={classes.answer}>
            A: All your scans are available on your{" "}
            <span className={classes.bold}>gallery under images folder.</span>.
            <br />
            <br />
            Besides the gallery you can also access your scanned images(jpg) and PDFs at the following location:
            <div className={classes.bold}>
              {"File Manager App->Documents->Kaagaz."}
            </div>
            <br />
            <br />
            If you download a scanned document as pdf, it gets stored in the{" "}
            <span className={classes.bold}>Downloads</span> folder.
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default FAQ;
