import { IonButton, IonIcon } from "@ionic/react";
import pdfIcon from "../../static/images/icons/pdf.svg";
import React from "react";
import "./PDF.css";
import { useHistory } from "react-router";

interface Props {
  id: string;
}

const PDF: React.FC<Props> = (props) => {
  const history = useHistory();
  return (
    <IonButton slot="start" onClick={() => history.push(`/pdf/${props.id}`)}>
      <IonIcon icon={pdfIcon} />
    </IonButton>
  );
};

export default PDF;
