import DocumentService from "../../services/DocumentService";
import { PDFBlob } from "../pdf";

export const downloadImageDoc = async (id: string) => {
  const documentData = await DocumentService.getDocument(id);

  const a = document.createElement("a");

  documentData.images.forEach(
    (imageData: { image: string; id: string }, i: number) => {
      a.download = `${documentData.name}-${i + 1}.jpg`;

      let base64 = imageData.image.split(",")[1];
      let binaryString = window.atob(base64);
      var bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      let blob = new Blob([bytes.buffer], { type: "image/jpeg" });
      a.href = URL.createObjectURL(blob);
      a.click();
      URL.revokeObjectURL(a.href);
    }
  );
};

export const downloadPDFDoc = async (id: string) => {
  const { blob, name } = await PDFBlob(id);
  const a = document.createElement("a");
  a.download = `${name}.pdf`;
  a.href = URL.createObjectURL(blob);
  a.click();

};
