import {
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonList,
  IonButton,
} from "@ionic/react";
import React from "react";
import classes from "./styles.module.css";
import image1 from "../../static/images/AppInnovationChallenge.jpg";
import image2 from "../../static/images/MinistryOfEducation.jpg";
import image3 from "../../static/images/MeitY.jpg";
import image4 from "../../static/images/EduMinister.jpeg";
import image5 from "../../static/images/MannKiBaat.jpg";

const data = [
  {
    title: "Special Mention in Aatma Nirbhar App Challenge",
    image: image1,
  },
  {
    title: "Recognized by Ministry of Education",
    image: image2,
  },
  { title: "Shout out by Digital India/MeitY", image: image3 },
  {
    title: "Compliments by Education Minister of India",
    image: image4,
  },
  {
    title: "Kaagaz Scanner mentioned in Mann ki Baat",
    image: image5,
  },
];

const Recognitions: React.FC = () => (
  <IonPage>
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>Our Recognitions</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <IonList>
        {data.map(({ title, image }, i) => (
          <div key={i} className={classes.item}>
            <div>{title}</div>
            <img className={classes.image} src={image} />
            <IonButton
              onClick={() => {
                // @ts-ignore
                if (navigator.share) {
                  navigator
                  // @ts-ignore
                    .share({
                      title: "Kaagaz Scanner",
                      text: "Kaagaz Scanner has been recognised by Indian Government.\nDownload now and start scanning.\n",
                      url: "https://kaagaz.app/download/12",
                    })
                    .then(() => console.log("Successful share"))
                    .catch((error: any) => console.log(error));
                }
              }}
            >
              Share
            </IonButton>
          </div>
        ))}
      </IonList>
    </IonContent>
  </IonPage>
);

export default Recognitions;
