export default {
    clients: {
        indexedDB: {
            database: 'kagaz',
            version: 1,
            stores: {
                documents: {
                    name: 'documents',
                    primaryKey: 'id'
                },
                images: {
                    name: 'images',
                    primaryKey: 'id'
                }
            }
        },
        localStorage: {
            watermark: 'Be AatmaNirbhar. Use Kaagaz',
            shutterSound: true,
            language: 'english'
        }
    },
    languages: [
        'english'
    ],
    email: "hello@sortedai.com",
};