import { IonLoading, IonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import DocumentService from "../../services/DocumentService";
import { IDocumentId } from "../typings";
import Camera from "./camera";
import Crop from "./crop";
import Effect from "./effect";
import { Actions, WorkerService } from "./service";
import { ImageObject } from "./typings";

enum ScanStage {
  Camera,
  Crop,
  Effect,
}

export enum Effects {
  Normal,
  Magic,
  BW,
  Gray,
}

const Scan: React.FC = () => {
  let { documentId } = useParams<IDocumentId>();

  const [stage, setStage] = useState<ScanStage>(ScanStage.Camera);
  const [images, setImages] = useState<ImageObject[]>([]);
  const [toast, setToast] = useState<boolean>(false);
  const [current, setCurrent] = useState<ImageObject>();
  const [effectSrc, setEffectSrc] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  const onNextFromCamera = async (imagesFromCamera: ImageObject[]) => {
    if (imagesFromCamera.length == 0) {
      setToast(true);
      return;
    }
    setLoading(true);
    const lowResImages: ImageObject[] = imagesFromCamera;
    setImages(lowResImages);
    setCurrent(lowResImages[0]);
    setStage(ScanStage.Crop);
    setLoading(false);
  };

  const onNextFromCrop = async (imageObj: ImageObject) => {
    const i = images.findIndex((item) => item.id === imageObj.id);
    if (i < 0) return;
    images[i] = imageObj;
    setImages(images);
    if (i === images.length - 1) {
      const result = (await WorkerService.dispatch(
        Actions.Crop,
        images[0]
      )) as any;
      setEffectSrc(result);
      setStage(ScanStage.Effect);
      return;
    }
    setCurrent(images[i + 1]);
  };

  const onBackFromCrop = async (id: string) => {
    const i = images.findIndex((item) => item.id === id);
    if (i < 0) return;
    if (i === 0) {
      setStage(ScanStage.Camera);
      return;
    }
    setCurrent(images[i - 1]);
  };

  const onNextFromEffect = async (effect: Effects) => {
    setLoading(true);
    const newImages = [];
    for (let item of images) {
      newImages.push(
        await WorkerService.dispatch(Actions.CropnEffect, { ...item, effect })
      );
    }
    if (!documentId) {
      documentId = await DocumentService.addDocument(newImages);
    } else {
      await DocumentService.addDocumentImage(documentId, newImages);
    }
    history.push(`/document/${documentId}`);
    setLoading(false);
  };

  return (
    <>
      <IonToast
        isOpen={toast}
        onDidDismiss={() => setToast(false)}
        message="Please select/click image to proceed"
        duration={1500}
      />
      <IonLoading isOpen={loading} message={"Please wait..."} />
      {stage === ScanStage.Camera && (
        <Camera existingImages={images} onNext={onNextFromCamera} />
      )}
      {stage === ScanStage.Crop && current && (
        <Crop
          imageObj={current}
          index={images.findIndex((item) => item.id === current.id)}
          total={images.length}
          onNext={onNextFromCrop}
          onBack={onBackFromCrop}
        />
      )}
      {stage === ScanStage.Effect && effectSrc && (
        <Effect
          imageSrc={effectSrc}
          onNext={onNextFromEffect}
          onBack={() => setStage(ScanStage.Crop)}
        />
      )}
    </>
  );
};

export default Scan;
