import React, { useState } from "react";
import {
  IonPopover,
  IonList,
  IonIcon,
  IonItem,
  IonLabel,
  IonContent,
} from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";

interface Props {
  sortBy: (input: string | null) => void;
}

const Kebab: React.FC<Props> = (props) => {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <>
      <IonPopover
        isOpen={showPopover}
        onDidDismiss={(e) => setShowPopover(false)}
      >
        <IonContent>
          <IonList>
            <IonItem
              button
              onClick={() => {
                props.sortBy("name");
                setShowPopover(false);
              }}
            >
              <IonLabel>Sort by name</IonLabel>
            </IonItem>
            <IonItem
              button
              onClick={() => {
                props.sortBy("date");
                setShowPopover(false);
              }}
            >
              <IonLabel>Sort by date</IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonPopover>
      <IonIcon
        style={{
          marginLeft: "8px",
          marginRight: "8px",
          fontSize: "1.2rem",
        }}
        slot="end"
        onClick={() => setShowPopover(true)}
        icon={ellipsisVertical}
      />
    </>
  );
};

export default Kebab;
