import React, { useState } from "react";
import { IonButton, IonIcon, IonRouterLink } from "@ionic/react";
import ReactStars from "react-stars";
import { closeOutline } from "ionicons/icons";
import config from "../../config";
import classes from "./styles.module.css";

interface Props {
  setShowPopover: (input: boolean) => void;
}

const RateUsDialog: React.FC<Props> = ({ setShowPopover }) => {
  const [rating, setRating] = useState(0);
  const [email, setEmail] = useState("");

  return (
    <div className={classes.container}>
      <IonIcon
        className={classes.close}
        onClick={() => setShowPopover(false)}
        icon={closeOutline}
      />
      <div className={classes.title}>Rate Us</div>
      <ReactStars
        count={5}
        value={rating}
        onChange={(newRating: number) => setRating(newRating)}
        size={24}
        color2={"var(--ion-color-primary)"}
        half={false}
        className={classes.stars}
      />
      <input
        className={classes.input}
        value={email}
        placeholder="Please provide your email id"
        onChange={(e) => setEmail(e.target.value!)}
      />
      <IonButton
        color="primary"
        onClick={() => setShowPopover(false)}
        size="small"
      >
        Submit
      </IonButton>
      <IonRouterLink href={`mailto:${config.email}`} className={classes.link}>
        Or email us at {config.email}
      </IonRouterLink>
    </div>
  );
};

export default RateUsDialog;
