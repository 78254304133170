import React from "react";
import { IonButton } from "@ionic/react";
import classes from "./styles.module.css";

interface DeleteProps {
  onCancel: () => void;
  onSubmit: () => void;
}

const DeleteDialog: React.FC<DeleteProps> = ({ onCancel, onSubmit }) => {
  return (
    <div className={classes.container}>
      <div className={classes.title}>Delete File</div>
      <div className={classes.content}>
        Are you sure you want to delete this file?
      </div>
      <div className={classes.buttons}>
        <IonButton fill="clear" size="small" onClick={onCancel}>
          NO
        </IonButton>
        <IonButton fill="clear" size="small" onClick={onSubmit}>
          YES, DELETE
        </IonButton>
      </div>
    </div>
  );
};

export default DeleteDialog;
