import IndexedDB from './IndexedDB';
import config from '../config';

class ObjectStore extends IndexedDB {
    constructor(store) {
        super();

        this.getStoreName = function() {
            return store.name;
        }

        this.getStorePrimaryKey = function() {
            return store.primaryKey;
        }

        this.getStoreName = this.getStoreName.bind(this);
        this.getStorePrimaryKey = this.getStorePrimaryKey.bind(this);
    }

    getWriteTransaction() {
        const state = this.getState();

        if (state === 'connecting') {
            return new Error('Not connected yet');
        }

        if (state === 'error') {
            return new Error('Error occurred while connecting to database');
        }

        const name = this.getStoreName();
        const db = this.getDatabase();
        const request = db.transaction(name, 'readwrite').objectStore(name);
        return request;
    }

    getReadTransaction() {
        const state = this.getState();

        if (state === 'connecting') {
            return new Error('Not connected yet');
        }

        if (state === 'error') {
            return new Error('Error occurred while connecting to database');
        }

        const name = this.getStoreName();
        const db = this.getDatabase();
        const request = db.transaction(name).objectStore(name);
        return request;
    }

    addItem(item) {
        try {
            const transaction = this.getWriteTransaction();
            return new Promise((resolve, reject) => {
                const request = transaction.add(item);
    
                request.onsuccess = function() {
                    return resolve('Added successfully');
                }
    
                request.onerror = function() {
                    if (request.error.name === 'ConstraintError'){
                        return reject('Item with the same primary key already exists. Consider updating or deleting and then adding');
                    }
                    console.error(request.error);
                    return reject(request.error);
                }
            });
        } catch(err) {
            return err;
        }
    }

    updateItem(item) {
        try {
            const transaction = this.getWriteTransaction();
            return new Promise((resolve, reject) => {
                const request = transaction.put(item);
    
                request.onsuccess = function() {
                    return resolve('Updated successfully');
                }
    
                request.onerror = function() {
                    console.error(request.error);
                    return reject(request.error);
                }
            });
        } catch(err) {
            return err;
        }
    }

    deleteItem(id) {
        try {
            const transaction = this.getWriteTransaction();
            return new Promise((resolve, reject) => {
                const request = transaction.delete(id);
    
                request.onsuccess = function() {
                    return resolve('Deleted successfully');
                }
    
                request.onerror = function() {
                    console.error(request.error);
                    return reject(request.error);
                }
            });
        } catch(err) {
            return err;
        }
    }

    getItem(id) {
        try {
            const transaction = this.getReadTransaction();
            return new Promise((resolve, reject) => {
                const request = transaction.get(id);
    
                request.onsuccess = function() {
                    if (request.result !== undefined) {
                        return resolve(request.result);
                    }
                    else {
                        return resolve(null);
                    }
                }
    
                request.onerror = function() {
                    console.error(request.error);
                    return reject(request.error);
                }
            });
        } catch(err) {
            return err;
        }
    }

    getAllItems() {
        try {
            const transaction = this.getReadTransaction();
            return new Promise((resolve, reject) => {
                const request = transaction.getAll();

                request.onsuccess = function() {
                    if (request.result.length) {
                        return resolve(request.result);
                    }
                    else {
                        return resolve(null);
                    }
                }
    
                request.onerror = function() {
                    console.error(request.error);
                    return reject(request.error);
                }
            });
        } catch(err) {
            return err;
        }
    }

    clearAllItems() {
        try {
            const transaction = this.getWriteTransaction();
            return new Promise((resolve, reject) => {
                const request = transaction.clear();

                request.onsuccess = function() {
                    return resolve('Cleared all data');
                }
    
                request.onerror = function() {
                    console.error(request.error);
                    return reject(request.error);
                }
            });
        } catch(err) {
            return err;
        }
    }
}


export const documentStore = new ObjectStore(config.clients.indexedDB.stores['documents']);
export const imageStore = new ObjectStore(config.clients.indexedDB.stores['images']);