import { v4 as uuidv4 } from "uuid";
import { getImageData } from "./helper";

export enum Actions {
  Load,
  Crop,
  Effect,
  LowRes,
  CropnEffect,
  RotateLeft,
  RotateRight,
}

export class WorkerService {
  static worker: any;
  static load = async () => {

    WorkerService.worker = new Worker("./worker.js");
    return await WorkerService.dispatch(Actions.Load);
  };

  static unload = () => {
    WorkerService.worker.terminate();
  };

  static dispatch = async (action: Actions, data?: any) => {
    let d = {};
    if (action !== Actions.Load) {
      d = (await getImageData(data.image)) as any;
    }
    WorkerService.worker.postMessage({
      action,
      data: { ...data, ...d },
    });
    return new Promise((resolve, reject) => {
      WorkerService.worker.onmessage = (e: any) => {
        const { success, imageData, width, height, error } = e.data;
        if (success) {
          if (action === Actions.Load) {
            console.log("Worker service loaded");
            return resolve(true);
          }
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = width;
          canvas.height = height;
          setTimeout(() => {
            ctx?.putImageData(imageData, 0, 0);
            return resolve(canvas.toDataURL("image/jpeg"));
          }, 500);
        } else {
          return reject(error);
        }
      };
    });
  };
}
