import React, { useState } from "react";
import { IonPopover } from "@ionic/react";

import deleteIcon from "../../../static/images/icons/delete-white.svg";
import downloadIcon from "../../../static/images/icons/download-white.svg";
import shareIcon from "../../../static/images/icons/share-white.svg";

import DocumentService from "../../../services/DocumentService";
import ImageService from "../../../services/ImageService";

import IconButton from "../../../components/IconButton";
import classes from "./styles.module.css";
import DeleteDialog from "../delete";
import { useHistory } from "react-router";

interface Props {
  setIsSelectionModeOn: (input: boolean) => void;
  selectedImages: string[];
  id: string;
  onDelete: () => void;
}

const downloadImages = async (id: string, selectedImageIds: string[]) => {
  const { name, images } = await DocumentService.getDocument(id);
  const imageIds = images.map((i: any) => i.id);

  const a = document.createElement("a");

  (async function downloadSingleImage(i: number) {
    if (i >= selectedImageIds.length) {
      return;
    }

    const imageId = selectedImageIds[i];
    const imageData = images.find((i: any) => i.id === imageId);
    const index = imageIds.indexOf(imageId) as any;

    a.download = `${name}-${index + 1}.jpg`;

    const base64 = imageData.image.split(",")[1];
    const binaryString = window.atob(base64);
    let bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([bytes.buffer], { type: "image/jpeg" });
    a.href = URL.createObjectURL(blob);
    a.click();
    URL.revokeObjectURL(a.href);

    downloadSingleImage(i + 1);
  })(0);
};

async function shareImages(selectedImageIds: string[]) {
  //@ts-ignore
  if (!navigator.share) return;

  const images = await Promise.all(
    selectedImageIds.map((id) => {
      return ImageService.getImage(id);
    })
  );

  const files = images.map(({ image, id }: any) => {
    const arr = image.split(",");
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], id + ".jpeg", {
      type: arr[0].match(/:(.*?);/)[1],
    });
  });

  //@ts-ignore
  if (navigator.canShare && navigator.canShare({ files })) {
    try {
      //@ts-ignore
      await navigator.share({ files, title: "Kaagaz Scanner", text: "" });
      console.log("Share was successful.");
    } catch (error) {
      console.log("Sharing failed", error);
    }
  } else {
    console.log(`Your system doesn't support sharing files.`);
  }
}

const SelectedImagesOptionTray: React.FC<Props> = ({
  id,
  onDelete,
  selectedImages,
  setIsSelectionModeOn,
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const deleteImages = async () => {
    try {
      await Promise.all(
        selectedImages.map((item) =>
          DocumentService.deleteDocumentImage(id, item)
        )
      );
      setShowDeleteDialog(false);
      setIsSelectionModeOn(false);
      onDelete();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className={classes.buttons}>
        <IconButton
          icon={deleteIcon}
          size="large"
          label="Delete"
          labelColor="white"
          labelSize="0.8rem"
          iconSize="30px"
          onClick={() => setShowDeleteDialog(true)}
        />

        <IconButton
          icon={downloadIcon}
          size="large"
          label="Download"
          labelColor="white"
          labelSize="0.8rem"
          iconSize="30px"
          onClick={() => downloadImages(id, selectedImages)}
        />

        <IconButton
          icon={shareIcon}
          label="Share"
          labelColor="white"
          labelSize="0.8rem"
          iconSize="30px"
          onClick={() => shareImages(selectedImages)}
        />
      </div>

      <IonPopover
        isOpen={showDeleteDialog}
        onDidDismiss={(e) => setShowDeleteDialog(false)}
      >
        <DeleteDialog
          onCancel={() => setShowDeleteDialog(false)}
          onSubmit={deleteImages}
        />
      </IonPopover>
    </>
  );
};

export default SelectedImagesOptionTray;
