import {
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonToggle,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonPopover,
  IonText,
} from "@ionic/react";
import React, { useState } from "react";
import UpdateLanguageDialog from "../../components/language";
import UpdateWatermarkDialog from "../../components/watermark";
import CacheService from "../../services/CacheService";

const Settings: React.FC = () => {
  const [showLanguagePopover, setShowLanguagePopover] = useState(false);
  const [showWatermarkPopover, setShowWatermarkPopover] = useState(false);
  const [cameraShutterSound, setCameraShutterSound] = useState(
    CacheService.getShutterSound()
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonText color="primary">
          <div className="ion-margin-top ion-margin-start">Prefrences</div>
        </IonText>
        <IonList>
          <IonItem
            lines="none"
            button
            onClick={() => setShowLanguagePopover(true)}
          >
            Change App Language
          </IonItem>
          <IonItem
            lines="none"
            button
            onClick={() => setShowWatermarkPopover(true)}
          >
            Add your own Watermark
          </IonItem>
          {/* Commenting out because sound not available */}
          {/* <IonItem lines="none">
            Camera shutter sound
            <IonToggle
              slot="end"
              color="danger"
              checked={cameraShutterSound}
              onIonChange={(e) => {
                setCameraShutterSound(e.detail.value);
                CacheService.toggleShutterSound();
              }}
            />
          </IonItem> */}
        </IonList>
        <IonPopover
          isOpen={showLanguagePopover}
          onDidDismiss={() => setShowLanguagePopover(false)}
        >
          <UpdateLanguageDialog setShowPopover={setShowLanguagePopover} />
        </IonPopover>
        <IonPopover
          isOpen={showWatermarkPopover}
          onDidDismiss={() => setShowWatermarkPopover(false)}
        >
          <UpdateWatermarkDialog setShowPopover={setShowWatermarkPopover} />
        </IonPopover>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
