import { v4 as uuidv4 } from "uuid";

import {imageStore} from '../clients/ObjectStore';

class ImageService {
    static async addImage(image) {
        const imageItem = {
            id: uuidv4(),
            // id: '1',
            dateCreated: Date.now(),
            lastModified: Date.now(),
            image,
        }

        try {
            await imageStore.addItem(imageItem);
            return Promise.resolve(imageItem.id);
        } catch(err) {
            return Promise.reject(err);
        }
    }

    static async updateImage(imageId, newImage) {
        try {
            const imageData = await imageStore.getItem(imageId);

            imageData.image = newImage;
            imageData.lastModified = Date.now();
    
            return Promise.resolve(imageStore.updateItem(imageData));
        } catch (err){
            return Promise.reject(err);
        }
    }

    static deleteImage(imageId) {
        return imageStore.deleteItem(imageId);
    }

    static getImage(imageId) {
        return imageStore.getItem(imageId);
    }

    static load() {
        //Necessary to run this as export does not run the file by it self
        //object store file gets imported so code is initialized
        //state of db changes to connected
        console.log('Image service loaded');
    }

    static clear() {
        return imageStore.clearAllItems();
    }
}

export default ImageService;