import { IonButton, IonIcon } from "@ionic/react";

import backButtonIcon from "../../static/images/icons/backpress.svg";

import React from "react";
import "./BackButton.css";
import { useHistory } from "react-router";

interface Props {
  location: string;
}

const BackButton: React.FC<Props> = (props) => {
  const history = useHistory();
  return (
    <IonButton slot="start" onClick={() => history.push(props.location)}>
      <IonIcon icon={backButtonIcon} />
    </IonButton>
  );
};

export default BackButton;
