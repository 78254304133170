import { IonIcon } from "@ionic/react";
import React from "react";
import classes from "./styles.module.css";
import classNames from "classnames";

type Transform =
  | "normal"
  | "rotate-90"
  | "rotate-180"
  | "rotate-270"
  | "flip-horizontal"
  | "flip-vertical";
interface IconButtonProps {
  icon: string;
  iconSize?:string;
  label: string;
  labelSize?:string;
  size?: string;
  color?: string;
  iconColor?: string;
  labelColor?: string;
  transform?: Transform;
  isSelected?: boolean;
  onClick: () => void;
}

const getTransformClass = (type: Transform) => {
  switch (type) {
    case "normal":
      return "";
    case "rotate-90":
      return classes.rotate_90;
    case "rotate-180":
      return classes.rotate_180;
    case "rotate-270":
      return classes.rotate_270;
    case "flip-horizontal":
      return classes.flip_horizontal;
    case "flip-vertical":
      return classes.flip_vertical;
  }
};

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  iconSize,
  label,
  labelSize,
  size,
  color,
  iconColor,
  labelColor,
  transform = "normal",
  isSelected = false,
  onClick,
}: IconButtonProps) => (
  <div
    className={classNames(classes.icon_button, {
      [classes.border_bottom]: isSelected,
    })}
    style={color && !iconColor && !labelColor ? { color } : {}}
    onClick={onClick}
  >
    <IonIcon
      className={classNames(classes.icon, getTransformClass(transform))}
      icon={icon}
      size={size}
      color={iconColor}
      style={iconSize ? { width: iconSize, height: iconSize } : {}}
    />
    <div
      className={classes.label}
      style={labelColor ? { color: labelColor, fontSize: labelSize } : {}}
    >
      {label}
    </div>
  </div>
);

export default IconButton;
