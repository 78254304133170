import { Dimension, Point } from "../typings";
import {
  add,
  drawCircle,
  drawLine,
  midPoint,
  slope,
  withInRadius,
} from "./helper";

class State<T> {
  value: T;
  set = (v: T) => (this.value = v);
  constructor(v: T) {
    this.value = v;
  }
}

class Frame {
  static TL: State<Point> = new State({ x: 20, y: 20 });
  static TR: State<Point> = new State({ x: 200, y: 20 });
  static BL: State<Point> = new State({ x: 20, y: 200 });
  static BR: State<Point> = new State({ x: 200, y: 200 });
  static corners = [Frame.TL, Frame.TR, Frame.BL, Frame.BR];
  static midPoints = [
    [Frame.TL, Frame.TR],
    [Frame.BL, Frame.BR],
    [Frame.TL, Frame.BL],
    [Frame.TR, Frame.BR],
  ];
  static canvas: HTMLCanvasElement;

  static init = (canvas: HTMLCanvasElement) => {
    Frame.canvas = canvas;
  };

  static setDimension = ({ width, height, top, left }: Dimension) => {
    const { canvas, paint } = Frame;
    canvas.setAttribute("style", `margin-left:${left}px; margin-top:${top}px`);
    canvas.width = width;
    canvas.height = height;
    const checkAndPaint = () =>
      Math.abs(canvas.width - width) < 2 &&
      Math.abs(canvas.height - height) < 2 &&
      paint();
    setTimeout(checkAndPaint, 300);
  };

  static paint = () => {
    const ctx = Frame.canvas?.getContext("2d");
    if (!ctx) return;
    ctx.clearRect(0, 0, Frame.canvas.width, Frame.canvas.height);
    Frame.corners.forEach((p) => p.value && drawCircle(ctx, p.value));
    Frame.midPoints.forEach(([a, b]) => {
      if (a.value && b.value) {
        drawCircle(ctx, midPoint(a.value, b.value));
        drawLine(ctx, a.value, b.value);
      }
    });
  };

  static onCanvasTouch = (e: React.TouchEvent<HTMLCanvasElement>) => {
    const { clientX, clientY } = e.touches[0];
    const { canvas, corners, midPoints, paint } = Frame;
    const ctx = canvas?.getContext("2d");
    if (!ctx) return;
    const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = canvas;
    const t: Point = {
      x: clientX - offsetLeft,
      y: clientY - offsetTop,
    };

    if (t.x < 0 || t.x > offsetWidth) return;
    if (t.y < 0 || t.y > offsetHeight) return;

    for (let p of corners) {
      if (p.value && withInRadius(p.value, t)) {
        p.set(t);
        paint();
      }
    }
    for (let [a, b] of midPoints) {
      if (a.value && b.value) {
        const m = midPoint(a.value, b.value);
        if (withInRadius(m, t)) {
          const p = slope(a.value, b.value)
            ? { x: t.x - m.x, y: 0 }
            : { x: 0, y: t.y - m.y };
          a.set(add(a.value, p));
          b.set(add(b.value, p));
          paint();
        }
      }
    }
  };
  static onNoCrop = () => {
    const { width, height } = Frame.canvas;
    Frame.TL.set({ x: 0, y: 0 });
    Frame.TR.set({ x: width, y: 0 });
    Frame.BL.set({ x: 0, y: height });
    Frame.BR.set({ x: width, y: height });
    Frame.paint();
  };
}

export default Frame;
