import React, { useState } from "react";
import { IonButton, IonTitle, IonIcon, IonInput } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import CacheService from "../../services/CacheService";
import classes from "./styles.module.css";

interface Props {
  setShowPopover: (input: boolean) => void;
}

const UpdateWatermarkDialog: React.FC<Props> = ({ setShowPopover }) => {
  const watermark = CacheService.getWatermark() || "";
  const [inputString, setInputString] = useState(watermark);

  return (
    <div className={classes.container}>
      <IonIcon
        className={classes.close}
        onClick={() => setShowPopover(false)}
        icon={closeOutline}
      />
      <div className={classes.title}>Add your own Watermark</div>
      <input
        className={classes.input}
        value={inputString}
        placeholder={watermark!}
        onChange={(e) => setInputString(e.target.value!.substring(0, 30))}
      />
      <IonButton
        color="primary"
        onClick={() => {
          CacheService.setWatermark(inputString);
          setShowPopover(false);
        }}
        size="small"
      >
        Submit
      </IonButton>
    </div>
  );
};

export default UpdateWatermarkDialog;
