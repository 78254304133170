import {
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import BackButton from "../../components/DocumentScreen/BackButton";
import DocumentService from "../../services/DocumentService";
import classes from "./styles.module.css";

interface URLParams {
  documentId: string;
  imageId: string;
}

interface IImageItem {
  id: string;
  dateCreated: Date;
  lastModified: Date;
  image: string;
}

const Single: React.FC = () => {
  const { documentId, imageId } = useParams<URLParams>();
  const [images, setImages] = useState<IImageItem[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const router = useHistory();
  const slidesRef = useRef(null);

  useEffect(() => {
    (async function () {
      try {
        const documentData = await DocumentService.getDocument(documentId);
        if (!documentData) {
          router.push("/404/");
        } else {
          setImages(documentData.images);
        }
      } catch (e) {
        router.push("/404/");
      }
    })();
  }, []);

  const onIonSlideDidChange = async () => {
    setActiveIndex(await (slidesRef.current as any)?.getActiveIndex());
  };

  const currIndex = images.findIndex(({ id }) => id === imageId);

  if (images.length === 0) {
    return <IonLoading isOpen={true} message={"Please wait..."} />;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <BackButton location={`/document/${documentId}`} />
          </IonButtons>
          <IonTitle>Page {activeIndex + 1}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={classes.center}>
          <IonSlides
            pager={false}
            options={{ initialSlide: currIndex }}
            ref={slidesRef}
            onIonSlideDidChange={onIonSlideDidChange}
          >
            {images.map((item) => (
              <IonSlide key={item.id}>
                <img src={item.image} />
              </IonSlide>
            ))}
          </IonSlides>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Single;
