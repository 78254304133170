import { IonHeader, IonPage, IonToolbar, IonTitle, IonContent } from '@ionic/react';
import React from 'react';

const _404: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            404
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        Requested content Not FOund
      </IonContent>
    </IonPage>
  );
}

export default _404;