import React, { useState, useEffect } from "react";
import {
  IonPopover,
  IonButton,
  IonList,
  IonIcon,
  IonItem,
  IonLabel,
  IonContent,
} from "@ionic/react";

import kebabIcon from "../../static/images/icons/more-vertical-white.svg";


import "./Kebab.css";

interface Props {
  setShowRenamePopover: (input: boolean) => void;
  setShowDeletePopover: (input: boolean) => void;
  setShowSelectedFiles: (input: boolean) => void;
}

const Kebab: React.FC<Props> = (props) => {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <>
      <IonPopover
        isOpen={showPopover}
        cssClass="my-custom-class"
        onDidDismiss={() => setShowPopover(false)}
      >
        <IonContent>
          <IonList>
            <IonItem
              button
              lines="none"
              onClick={() => {
                props.setShowRenamePopover(true);
                setShowPopover(false);
              }}
            >
              <IonLabel>Rename</IonLabel>
            </IonItem>
            <IonItem
              button
              lines="none"
              onClick={() => {
                props.setShowDeletePopover(true);
                setShowPopover(false);
              }}
            >
              <IonLabel>Delete File</IonLabel>
            </IonItem>
            <IonItem
              lines="none"
              button
              onClick={() => {
                props.setShowSelectedFiles(true);
                setShowPopover(false);
              }}
            >
              <IonLabel>Select Pages</IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonPopover>
      <IonButton onClick={() => setShowPopover(true)} slot="end">
        <IonIcon icon={kebabIcon}></IonIcon>
      </IonButton>
    </>
  );
};

export default Kebab;
