import React, { useLayoutEffect, useState } from "react";
import IconButton from "../../../components/IconButton";
import classes from "./styles.module.css";

import backIcon from "../../../static/images/icons/backpress.svg";
import rightIcon from "../../../static/images/icons/rotate-cw.svg";
import leftIcon from "../../../static/images/icons/rotate-ccw.svg";
import noCropIcon from "../../../static/images/icons/no-crop-white.svg";

import { ImageObject, Point } from "../typings";
import { getImageElem } from "../helper";
import Frame from "./frame";
import { Actions, WorkerService } from "../service";
import { IonLoading } from "@ionic/react";

interface CropProps {
  imageObj: ImageObject;
  index: number;
  total: number;
  onNext: (i: ImageObject) => void;
  onBack: (id: string) => void;
}

const offset = 20;

const Crop: React.FC<CropProps> = ({
  imageObj,
  index,
  total,
  onNext,
  onBack,
}) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const imageRef = React.useRef<HTMLImageElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [imageSrc, setImageSrc] = useState<ImageObject>(imageObj);
  const [loading, setLoading] = useState<boolean>(false);

  const setDimension = () => {
    const image = imageRef.current;
    const container = containerRef.current;
    if (!image || !container) return;
    const { naturalWidth, naturalHeight } = image;
    const { offsetWidth, offsetHeight } = container;
    const imageAR = naturalWidth / naturalHeight;
    const containerAR = offsetWidth / offsetHeight;
    let width = 0,
      height = 0,
      top = 0,
      left = 0;
    if (imageAR > containerAR) {
      width = offsetWidth;
      height = width / imageAR;
      left = -offsetWidth;
      top = (offsetHeight - height) / 2;
    } else {
      height = offsetHeight;
      width = height * imageAR;
      left = -((offsetWidth + width) / 2);
      top = 0;
    }
    Frame.TL.set(imageObj.boundary?.TL || { x: offset, y: offset });
    Frame.TR.set(imageObj.boundary?.TR || { x: width - offset, y: offset });
    Frame.BL.set(imageObj.boundary?.BL || { x: offset, y: height - offset });
    Frame.BR.set(
      imageObj.boundary?.BR || { x: width - offset, y: height - offset }
    );
    Frame.setDimension({ width, height, top, left });
  };

  useLayoutEffect(() => {
    const canvas = canvasRef.current;
    const image = imageRef.current;
    if (!canvas || !image) return;
    Frame.init(canvas);
    image.onload = () => setDimension();
  }, []);

  if (imageSrc.id != imageObj.id) setImageSrc(imageObj);

  const onRotate = (action: Actions) => async () => {
    setLoading(true);
    const image = (await WorkerService.dispatch(action, imageSrc)) as string;
    setImageSrc({ ...imageSrc, image });
    setLoading(false);
  };

  const next = async () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const image = await getImageElem(imageSrc.image);
    const scaleX = (x: number) => (x * image.width) / canvas.width;
    const scaleY = (y: number) => (y * image.height) / canvas.height;
    const scale = (p: Point) => ({ x: scaleX(p.x), y: scaleY(p.y) });

    onNext({
      ...imageSrc,
      boundary: {
        TL: scale(Frame.TL.value!),
        TR: scale(Frame.TR.value!),
        BL: scale(Frame.BL.value!),
        BR: scale(Frame.BR.value!),
      },
    });
  };

  return (
    <div className={classes.container}>
      <IonLoading isOpen={loading} message={"Please wait..."} />
      <div className={classes.header}>
        Page {index + 1} of {total}
      </div>
      <div className={classes.content}>
        <div className={classes.inner_content} ref={containerRef}>
          <img className={classes.image} ref={imageRef} src={imageSrc.image} />
          <canvas
            className={classes.canvas}
            ref={canvasRef}
            onTouchMove={Frame.onCanvasTouch}
          />
        </div>
      </div>
      <div className={classes.footer}>
        <IconButton
          icon={backIcon}
          label="Back"
          iconSize="30px"
          onClick={() => onBack(imageObj.id)}
          labelColor="white"
        />
        <IconButton
          icon={leftIcon}
          label="Left"
          iconSize="30px"
          onClick={onRotate(Actions.RotateLeft)}
          labelColor="white"
        />
        <IconButton
          icon={rightIcon}
          label="Right"
          iconSize="30px"
          onClick={onRotate(Actions.RotateRight)}
          labelColor="white"
        />
        <IconButton
          icon={noCropIcon}
          label="No Crop"
          iconSize="30px"
          onClick={Frame.onNoCrop}
          labelColor="white"
        />
        <IconButton
          icon={backIcon}
          label="Next"
          iconSize="30px"
          onClick={next}
          transform="flip-horizontal"
          labelColor="white"
        />
      </div>
    </div>
  );
};

export default Crop;
